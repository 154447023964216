<template>
    <div class="approvalRecord">
        <!-- 簽核歷程-->
        <section v-if="recordData.length!==0">
            <div class="recordWrap">
                <el-table v-loading="loading" style="width: 100%"  :data="recordData" >                
                <el-table-column prop="CreateUser" label="申請人/覆核人" width="120">
                </el-table-column>
                <el-table-column prop="ListType" label="送審/覆核時間" width="150">
                    <template slot-scope="scope">
                        {{filterDate(scope.row.CreateDt)}}
                    </template>
                </el-table-column>
                <el-table-column prop="Status" label="狀態" width="150"></el-table-column>
                <el-table-column prop="Memo" label="備註" width="150"></el-table-column>
                </el-table>
            </div>
            <div class="suggestionBox" v-if="routeName!=='relatedNews'">
                <el-form :model="suggestion" v-if="routeName!=='relatedNews'">
                    <section v-if="(caseStatus==='edit' && role==='operator' && permission==='True')||caseStatus==='add'">
                        <!-- 承辦人送審備註 -->
                        <div class="suggestionWrap">
                            <div>
                                <p>送審備註：</p>
                                <textarea v-model.trim="suggestion.operator" name="" id="" cols="30" rows="10" class="text"></textarea>
                            </div>
                        </div>
                    </section>
                    <section v-if="(caseStatus==='wait_approval' && role==='manager')||(caseStatus==='wait_delete' && role==='manager')">
                        <!-- 管理員簽核意見-->
                        <div class="suggestionWrap">
                            <div>
                                <p>簽核意見：</p>
                                <textarea v-model.trim="suggestion.manager" name="" id="" cols="30" rows="10" class="text"></textarea>
                            </div>
                        </div>
                    </section>
                </el-form>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        props: {
            caseStatus:{
                type:String
            },
            role:{  
                type:String
            },
            data:{
                type:String
            },
            tListID: {
                type:String
            }  
        },
        data() {
            return {
                permission:this.$route.query.permission,
                routeName:this.$route.name,
                suggestion:{
                    operator:"",    
                    manager:""
                },
                requestData:{
                    // ListID:this.$route.query.listID,
                    ListID : this.tListID,
                    Page_Size:99,
                    Page_Num:1,
                },
                recordData:[]
            }
        },
        watch:{
            data(val){
                //console.log(val);
                if(!val){
                    this.suggestion.operator = ''
                }else{
                    this.suggestion.operator = val
                }
            },
            suggestion: {
                deep: true,
                handler(val) {
                    this.$emit('updateSuggestion', val);
                }
            }
        },
        computed:{
            filterDate() {
                return (date) => {
                    return this.$dayjs(date).format("YYYY-MM-DD HH:mm");
                };
            },
        },
        methods: {
            getApprovalRecord(){
                console.log("Record",this.requestData);
                this.$api.record.getPreviewList(this.requestData).then((res)=>{
                    this.recordData = res.data
                    console.log(res);
                })
            }
        },
        mounted() {
            if(this.caseStatus!=='add'){
                this.getApprovalRecord()
            }
        },  
    }
</script>

<style lang="scss" scoped>
    .approvalRecord{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: row;
        // outline: red solid 1px;
        justify-content: center;

        .recordWrap{
            width: 100%;
            // outline: red solid 1px;
        }
        .suggestionBox{
            width: 100%;
            // outline: red solid 1px;
            .suggestionWrap{
                width: 100%;
                p{
                    margin: 0px;
                }
                .text{
                    width: 600px;
                    margin-top: 10px;
                    padding: 0px;
                }
            }
        }

    }
</style>