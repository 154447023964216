<template>
  <div class="WarningFormBtn">
    <!-- 新增,編輯表單 -->
    <div v-if="btnStatus==='saveAndWaitApproval'">
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button>
      <el-button @click="doFunction('abandon')" type="primary" size="large" icon="el-icon-refresh-left">放棄</el-button>
      <el-button @click="doFunction('save')" type="primary" size="large" icon="el-icon-document-add" >儲存</el-button>
      <el-button @click="doFunction('waitApproval')" type="primary" size="large" icon="el-icon-share">送出</el-button>
    </div>
    <div v-else-if="btnStatus==='approvalAndReject'">
      <el-button @click="doFunction('approval')" type="primary" size="large" icon="el-icon-check">核准</el-button>
      <el-button @click="doFunction('reject')" type="primary" size="large" icon="el-icon-share" >退回</el-button>
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button> 
    </div>
    <div v-else-if="btnStatus==='approvalDeleteAndReject'">
      <el-button @click="doFunction('delete')" type="primary" size="large" icon="el-icon-delete">核准刪除</el-button>
      <el-button @click="doFunction('reject')" type="primary" size="large" icon="el-icon-share">退回</el-button>
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button> 
    </div>
    <div v-else-if="btnStatus==='cancel'">
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button>
    </div>
    <div v-else-if="btnStatus==='cancelAndSave'">
      <el-button @click="doFunction('addRelateInfo')" type="primary" size="large" icon="el-icon-plus">加入關聯資訊</el-button>
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button>
    </div>
     <div v-else-if="btnStatus==='cancelAndRevise'">
      <el-button @click="doFunction('revise')" type="primary" size="large" icon="el-icon-edit">修改</el-button>
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button>
    </div>
    <div v-else-if="btnStatus==='reviseAndDelete'">
      <el-button @click="doFunction('revise')" type="primary" size="large" icon="el-icon-edit">修改</el-button>
      <!--<el-button @click="doFunction('waitDelete')" type="primary" size="large" icon="el-icon-delete">刪除</el-button>-->
       <el-button @click="deleteNameList" type="primary" size="large" icon="el-icon-delete">刪除</el-button> 
      <el-button @click="doFunction('back')" type="primary" size="large" icon="el-icon-close">取消</el-button> 
    </div>
   
    <el-dialog title="請輸入刪除名單原因" :visible.sync="dialogStatus" width="50%" center>
      <div class="content"> 
        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="請輸入刪除名單原因" v-model="deleteReason"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogStatus = false" icon="el-icon-close">取消</el-button>
        <el-button type="primary" @click="deleteNameList" icon="el-icon-save">儲存</el-button>
      </span>
    </el-dialog>

    
   
  </div>
</template>

<script>
export default {
  props:{
    status:{
      typeof:String
    }
  },
  computed:{
    btnStatus:{
      get(){
          return this.status      // return // ...
      },
      set(){
          return this.status
      }
    },
  },
  data() {
    return {
      // status:"",
      // userRole:this.$store.state.userInfo.Role,
      decideCaseShowInfo:{
        actionsType: this.$route.query.action,
        permission:this.$route.query.permission,
        warningFormType: this.$route.query.warningFormType,
        userRole:this.$store.state.userInfo.Role
      },      
      dialogStatus : false,
      deleteReason : ""
    };
  },
  methods: {
    doFunction(btnType){
      //alert(btnType);
      this.$emit("doFunction",btnType);
    },

    deleteNameList() {

      //doFunction('waitDelete');
      //dialogStatus = false;
      //alert('Delete ....');
      this.$confirm("確定要刪除？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(()=>{
          
          this.$emit("doFunction",'waitDelete');
        }
      );
      //this.$emit("doFunction",btnType);
    }
    // handleCaseStatus(){
    //   //承辦人新增表單
    //   if(this.decideCaseShowInfo.actionsType==='add' && this.decideCaseShowInfo.userRole==='operator'){
    //     this.status = 'saveAndSend'
    //   }
    //   //承辦人在案件是編輯狀態
    //   if(this.decideCaseShowInfo.actionsType==='edit' && this.decideCaseShowInfo.userRole==='operator'){
    //     this.status = 'saveAndSend'
    //   }
    //   //主管在案件是編輯狀態
    //   if(this.decideCaseShowInfo.actionsType==='edit' && this.decideCaseShowInfo.userRole==='manager'){
    //     this.status = 'preview'
    //   }
    // },
  },
  mounted() {
    //console.log(this.$store.state.userInfo)
  },
};
</script>

<style lang="scss" scoped>
.WarningFormBtn {
  text-align: center;
  padding: 5%;
}
</style>
