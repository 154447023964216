<template>
  <div class="tabWrap">
    <span v-for="(item, index) in tabList" :key="item.id" :class="{ active: defaultTab == index }"
      @click="handleTab(item.enTitle)"
    >
      {{ item.title }}
    </span>
  </div>
</template> 

<script>
export default {
  props: {
    typeNewsProp: {
      type: String,
    },
  },
  data() {
    return {
      tabList: [
        {
          id: 0,
          title: "負面新聞",
          enTitle: "searchNews",
        },
        {
          id: 1,
          title: "聯合國制裁名單",
          enTitle: "searChsan",
        },
        {
          id: 2,
          title: "政府拒絕往來名單",
          enTitle: "searchConstr",
        },
        {
          id: 3,
          title: "違反勞動法名單",
          enTitle: "searchLabor",
        },
        {
          id: 4,
          title: "環保署裁罰名單",
          enTitle: "searchEnviroment",
        },
      ],
      defaultTab: 0,
      classObject: {
        active: false,
        original: true,
      },
    };
  },
  watch:{
    typeNews:{
      handler(val){
        if(val==='searchNews'){
          this.defaultTab = 0
        }else if(val==='searChsan'){
          this.defaultTab = 1
        }else if(val==='searchConstr'){
          this.defaultTab = 2
        }else if(val==='searchLabor'){
          this.defaultTab = 3
        }else if(val==='searchEnviroment'){
          this.defaultTab = 4
        }
      },
      immediate:true,
    }
  },
  computed: {
    typeNews(){
      return this.typeNewsProp
    },
  },
  methods: {
    handleTab(typeNews) {
      this.$emit("handleChangeTab", typeNews);
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.tabWrap {
  span {
    display: inline-block;
    text-align: center;
    width: 170px;
    height: 30px;
    padding: 10px 0px;
    background-color: #f5f5f5;
    margin-right: 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  .active {
    background-color: #e0ffff;
  }
}
</style>
